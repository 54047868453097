import Rails from "@rails/ujs"

window.addEventListener("load", () => {
    if (document.getElementById("lbp_rangeform")){
        //Next Page Control
        if(document.getElementById("lbp_next")){
            document.getElementById("lbp_next").addEventListener("click", function(){
                //get all the range values and statements
                var formData = document.getElementsByClassName("data");
                var na_boxes = document.getElementsByClassName("data2")
                //cleanse data
                var cFormData = [];            

                for(var i = 0; i < formData.length; i++)
                {
                    console.log(na_boxes[i].checked);
                    if(na_boxes[i].checked)
                        cFormData.push(11);
                    else
                        cFormData.push(formData[i].value);
                }

                //send data
                let data = new FormData();
                data.append("scores",cFormData);
                //console.log(data.entries);
                Rails.ajax({url: "/client/leader_balance_profile/:id/next", type: "POST", data: data, datatype: "json",
                    success: function(data){
                        console.log(data)
                        // window.location.href = "/client/leader_balance_profile/:id/next"
                        location.reload()
                    }
                })
                // location.reload()
            })
        }
        //Submit Control
        if(document.getElementById("lbp_submit"))
        {
            document.getElementById("lbp_submit").addEventListener("click", function(){
                //get all the range values and statements
                if(document.getElementsByClassName("data")){
                    var formData = document.getElementsByClassName("data");
                    
                    //cleanse data
                    var cFormData = [];            

                    for(var i = 0; i < formData.length; i++)
                    {
                        //console.log(formData[i].value);
                        cFormData.push(formData[i].value);
                    }

                    //send data
                    let data = new FormData();
                    data.append("scores",cFormData);
                    //console.log(data.entries);
                    var path = ""                   
                        
                            
                    let senddata = new Promise(function(resolve,reject){
                        Rails.ajax({url: "/client/leader_balance_profile/:id/submit", type: "POST", data: data, datatype: "json",
                            success: function(data){                              
                                console.log(data['path'])
                                
                                path = data['path']
                                resolve(path)
                            },error: function(err){
                                reject(err)
                            }

                        })
                    })                     
                    
                    senddata.then(
                        function(data){
                            window.location.href = data
                        }
                        
                    )
                    
                }
                else
                {
                    //send data
                    console.log("Nil or Empty Data");
                    let data = new FormData();
                    var path = ""                   
                        
                            
                    let senddata = new Promise(function(resolve,reject){
                        Rails.ajax({url: "/client/leader_balance_profile/:id/submit", type: "POST", data: data, datatype: "json",
                            success: function(data){                              
                                console.log(data['path'])
                                
                                path = data['path']
                                resolve(path)
                            },error: function(err){
                                reject(err)
                            }

                        })
                    })                     
                    
                    senddata.then(
                        function(data){
                            window.location.href = data
                        }
                        
                    )
                }
            })
        }
    }
})