// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "chartkick/chart.js"

import * as ActiveStorage from "@rails/activestorage"
import "channels"
//require("sortable")
import Sortable from 'sortablejs'
Rails.start()

ActiveStorage.start()

import "./lbp_controls"

//sortable scripts
    window.addEventListener('load', ()=>{
        window.matchMedia('print').addListener(function() {
            //get chartkick charts
            var chart1 = Chartkick.charts["chart1"]
            var chart2 = Chartkick.charts["chart2"]
            var chart3 = Chartkick.charts["chart3"]
            //redraw chart
            chart1.redraw()
            chart2.redraw()
            chart3.redraw()
            //update the background color of the chart
            // var charthtmlobject = document.getElementById("chart1")
            // charthtmlobject.style.backgroundColor = "#cccccc"
          });
        if(document.getElementsByClassName("sortable") && document.getElementsByClassName("sortable").length > 0){
            var el = document.getElementsByClassName("sortable")
            //for each element with class sortable, create a sortable with it
            for (var i = 0; i < el.length; i++) {
                Sortable.create(el[i],
                {
                    group: "group" + i
                })
            }
    
            //finished ajax call
            if(document.getElementById("finish")){
            document.getElementById("finish").addEventListener("click", function(){
                let groups = [[]]
    
                //get all elements with class sortable
                var el = document.getElementsByClassName("sortable")
                //for each element with class sortable, find the order of sortable-items and store in groups
                for (var i = 0; i < el.length; i++) {
                    var itemIds = []
                    var items = el[i].getElementsByClassName("sortable-item")
                    //for each item, push id to groupId
                    for(var j = 0; j < items.length; j++){
                        itemIds.push(items[j].id)
                    }
                    groups[i] = itemIds
                }
                //Perform Ajax call
                let data = new FormData()
                data.append("group_ids", groups)
                Rails.ajax({url: "/client/updateorder", type: "POST", data: data, datatype: "json",
                    success: function(data){
                        console.log(data)
                        
                    }
                })
                window.location.href = '/client/finishprofile'
            })}

            //finished ajax call
            if(document.getElementById("finish_career_rank_report")){
            document.getElementById("finish_career_rank_report").addEventListener("click", function(){
                let groups = [[]]
    
                //get all elements with class sortable
                var el = document.getElementsByClassName("sortable")
                //for each element with class sortable, find the order of sortable-items and store in groups
                for (var i = 0; i < el.length; i++) {
                    var itemIds = []
                    var items = el[i].getElementsByClassName("sortable-item")
                    //for each item, push id to groupId
                    for(var j = 0; j < items.length; j++){
                        itemIds.push(items[j].id)
                    }
                    groups[i] = itemIds
                }
                //Perform Ajax call
                let data = new FormData()
                data.append("group_ids", groups)
                Rails.ajax({url: "/client/updateorder_career_rank_report", type: "POST", data: data, datatype: "json",
                    success: function(data){
                        console.log(data)
                        window.location.href = '/client/finishprofile_career_rank_profile'
                    }
                })
                
            })}
        }
        else if (document.getElementById("rangeform"))
        {
            //Next Page Control
            if(document.getElementById("next")){
                document.getElementById("next").addEventListener("click", function(){
                    //get all the range values and statements
                    var formData = document.getElementsByClassName("data");
                    var na_boxes = document.getElementsByClassName("data2")
                    //cleanse data
                    var cFormData = [];            
    
                    for(var i = 0; i < formData.length; i++)
                    {
                        console.log(na_boxes[i].checked);
                        if(na_boxes[i].checked)
                            cFormData.push(11);
                        else
                            cFormData.push(formData[i].value);
                    }
    
                    //send data
                    let data = new FormData();
                    data.append("scores",cFormData);
                    //console.log(data.entries);
                    Rails.ajax({url: "/client/personal_balance_profile/:id/next", type: "POST", data: data, datatype: "json",
                        success: function(data){
                            console.log(data)
                            
                        }
                    })
                    location.reload()
                })
            }
            //Submit Control
            if(document.getElementById("submit"))
            {
                document.getElementById("submit").addEventListener("click", function(){
                    //get all the range values and statements
                    if(document.getElementsByClassName("data")){
                        var formData = document.getElementsByClassName("data");
                        
                        //cleanse data
                        var cFormData = [];            
    
                        for(var i = 0; i < formData.length; i++)
                        {
                            //console.log(formData[i].value);
                            cFormData.push(formData[i].value);
                        }
    
                        //send data
                        let data = new FormData();
                        data.append("scores",cFormData);
                        //console.log(data.entries);
                        var path = "/client/personal_balance_report"                   
                        
                            
                        let senddata = new Promise(function(resolve,reject){
                            Rails.ajax({url: "/client/personal_balance_profile/:id/submit", type: "POST", data: data, datatype: "json",
                                success: function(data){                              
                                    console.log(data['path'])
                                    
                                    path = data['path']
                                    resolve(path)
                                },error: function(err){
                                    reject(err)
                                }

                            })
                        })                     
                        
                        senddata.then(
                            function(data){
                                window.location.href = data
                            }
                            
                        )
                        
                        
                    }
                    else
                    {
                        var path = "/client/personal_balance_report"                   
                        
                            
                        let senddata = new Promise(function(resolve,reject){
                            Rails.ajax({url: "/client/personal_balance_profile/:id/submit", type: "POST", data: data, datatype: "json",
                                success: function(data){                              
                                    console.log(data['path'])
                                    
                                    path = data['path']
                                    resolve(path)
                                },error: function(err){
                                    reject(err)
                                }

                            })
                        })                     
                        
                        senddata.then(
                            function(data){
                                window.location.href = data
                            }
                            
                        )
                    }
                })
            }        
    
        }
        if(document.getElementById("profiles-index"))
        {
            var initialValues = []
            //hide save changes
            if(document.getElementById("save-changes"))
            {
                document.getElementById("save-changes").style.display = "none"
            }
            //Get all checkboxes
            var checkboxes = document.getElementsByClassName("show-report-box");
            //for each checkbox, add event listener
            for(var i = 0; i < checkboxes.length; i++)
            {   
                initialValues.push(checkboxes[i].checked)
                checkboxes[i].addEventListener("click", function(){
                    //if the checkbox is clicked then show the hidden
                    //save changes button
                    document.getElementById("save-changes").style.display = "block"                   
                })
            }
            //if the save changes button is clicked
            if(document.getElementById("save-changes"))
            {
                document.getElementById("save-changes").addEventListener("click", function(){
                    let data = new FormData()
                    var ids = []
                    //get all checkbox values that are different from initial values
                    for(var i = 0; i < checkboxes.length; i++)
                    {
                        if(initialValues[i] != checkboxes[i].checked)
                        {
                            //get the id of the checkbox
                            var id = checkboxes[i].id
                            ids.push(id)
                        }
                    }
                    //add ids to data
                    data.append("ids", ids)
                    //perform ajax call
                    Rails.ajax({url: "/profiles/update_show_report", type: "POST", data: data, datatype: "json",
                        success: function(data){
                            console.log(data)
                            
                        }
                    })
                    location.reload();
                })
            }
        }
    })
    
